class SidebarToggler {

    $sidebarToggler;
    $toggleClassOn;
    isSidebarOpen = false;

    config = {
        activeClassName: 'menu-open',
        hashKey: '',
        togglerId: 'sidebar-toggler',
        toggleClassOnId: 'app',
        developerModeOn: true,
        showSidebar: false
    };

    constructor( config ){
        this.config = Object.assign( {}, this.config, config);
        this.$sidebarToggler = document.getElementById(this.config.togglerId);
        this.$toggleClassOn = document.getElementById(this.config.toggleClassOnId);

        if( this.$sidebarToggler && this.$toggleClassOn){
            this.attachClickEvent();

            if( this.config.showSidebar ){
                this.open();
            }
            return;
        }

        if(this.config.developerModeOn){
            log.error('[SidebarToggler][constructor] - Required Dom elements are not found', {
                config: this.config
            });
        };
    }

    /**
     * Update the sidebar state
     *
     * @param state - determine to show or hide the sidebar
     * @returns void
     */
    toggle( ){
        if(!this.isSidebarOpen){
            this.open();
        }else {
            this.close();
        }
    }

    /**
     * Open sidebar
     *
     * @returns void
     */
    open(){
        this.$toggleClassOn.classList.add(this.config.activeClassName);
        this.isSidebarOpen = true;
        HashHelper.set(this.config.hashKey, true)
    }

    /**
     * Close sidebar
     *
     * @returns void
     */
    close(){
        this.$toggleClassOn.classList.remove(this.config.activeClassName);
        this.isSidebarOpen = false;
        HashHelper.set(this.config.hashKey, false);
    }

    /**
     * Attach the click event in sidebar toggler
     *
     * @returns void
     */
    attachClickEvent(){
        this.$sidebarToggler.addEventListener('click', ()=>{
            this.toggle();
        });
    }
}

class SidebarNavigation {
  /* Local properties */
  $listItem;
  flatMappedNavigationData = {};
  config = {
    selector: "right-panel",
    hashKey: "",
    navigationData: {},
    developerModeOn: true,
    onItemClicked: () => {},
    onAfterAppend: () => {},
  };

  constructor(config) {
    this.config = Object.assign({}, this.config, config);
  }

  /**
   * Activate the navigation item which is recently clicked
   *
   * @returns void
   */
  activeNavItemOnChange() {
    const _this = this;

    if (!this.menuItemSelector) {
      this.handleMenuItemNotFoundError();
      return;
    }

    // Add a click event listener to each list item
    this.menuItemSelector.forEach(function (listItem) {
      listItem.addEventListener("click", function (e) {
        e.preventDefault();

        _this.removeActiveClassFromList();

        // Add the "active" class to the clicked list item
        this.classList.add("active");

        const slug = this.dataset.slug;
        const fileLocation = _this.getFileLocationBySlug(slug);

        //Calling callback function for click event
        if (slug && fileLocation) {
          // Update the URL hash value
          HashHelper.set(_this.config.hashKey, slug);

          _this.config.onItemClicked(slug, fileLocation, this);
        }
      });
    });
  }

  /**
   * Provide the menu item selector
   *
   * @returns DOMElement
   */
  get menuItemSelector() {
    if (this.$listItems) {
      return this.$listItems;
    }
    return document.querySelectorAll(".nav-menu ul li a");
  }

  /**
   * Handle the menu item not found error
   * @returns void
   */
  handleMenuItemNotFoundError() {
    log.error(
      "[SidebarNavigation][activeNavItemOnChange] - Menu list items is not found in DOM"
    );
  }

  /**
   * Remove the "active" class from all list items
   *
   * @return void
   */
  removeActiveClassFromList() {
    if (!this.menuItemSelector) {
      this.handleMenuItemNotFoundError();
      return;
    }

    this.menuItemSelector.forEach(function (item) {
      item.classList.remove("active");
    });
  }

  /**
   * Provide the icon's full html
   *
   * @param iconClassName {string} - bootstrap icon class name
   * @returns void
   */
  getIconHtml(iconClassName) {
    return iconClassName ? `<i class="${iconClassName}"></i>` : "";
  }

  /**
   * Append navigation in sidebar
   *
   * @returns void
   */
  append() {
    const $navigationSelector = document.getElementById(this.config.selector);
    if ($navigationSelector) {
      $navigationSelector.innerHTML = this.template;
      this.config.onAfterAppend($navigationSelector);
      return;
    }

    // Logging the error message
    if (this.config.developerModeOn) {
      log.error(
        "[SidebarNavigation][append] -  Failed to append menu in sidebar",
        {
          selectorId: this.config.selector,
          config: this.config,
        }
      );
    }
  }

  /**
   * Generate sidebar navigation HTML template
   *
   * @returns string
   */
  get template() {
    if (!this?.config?.navigationData) {
      return "";
    }

    const generateTemplate = (navigationData) => {
      let template = "<ul>";

      navigationData.forEach((item) => {
        template += `<li>
                <a
                    href="#${item?.slug}"
                    data-slug="${item?.slug}">
                    ${this.getIconHtml(item.iconClassName)}
                    ${item.label}
                </a>`;

        if (item.children.length > 0) {
          template += generateTemplate(item.children);
        }
        template += "</li>";

        // Update the mapped file
        if (item?.slug) {
          this.flatMappedNavigationData[item.slug] = item;
        }
      });

      template += "</ul>";
      return template;
    };

    return generateTemplate(this.config.navigationData);
  }

  /**
   * The active menu item by slug
   * @param slug {string} - Component or block slug
   */
  activeMenuItemBySlug(slug) {
    const link = document.querySelector(`[href="#${slug}"]`);
    if (link) {
      this.removeActiveClassFromList();
      link.classList.add("active");
      return;
    }

    if (this.config.developerModeOn) {
      log.error(
        "[SidebarNavigation][activeMenuItemBySlug] - Invalid link selector",
        {
          selector: `[href="#${slug}"]`,
        }
      );
    }
  }

  /**
   * Provide file location by component and block's slug
   *
   * @param slug {string} - Component or block slug
   * @returns string - file location
   */
  getFileLocationBySlug(slug) {
    const menuItemData = this.getItemDataBySlug(slug);
    if (menuItemData) {
      return menuItemData.fileLocation;
    }
    return "";
  }

  /**
   * Provide the menu item data by slug
   *
   * @param slug {string} - Component or block slug
   * return - Menu item data
   */
  getItemDataBySlug(slug) {
    if (slug && this.flatMappedNavigationData[slug]) {
      return this.flatMappedNavigationData[slug];
    }
    return "";
  }
}

class Search {
  $inputSearch;
  $searchWrapper;
  $searchResultList;
  $activeList;

  searchedText;
  cachedFlatNavigationData;
  isSearchResultOpened = false;
  previouslySearchedText = "";

  classNames = {
    searchFound: "search-found",
    searchOpen: "search-open",
    emptyResult: "empty-result",
    activeList: "active",
  };

  config = {
    inputSearchId: "",
    listId: "",
    wrapperId: "",
    navigationData: "",
    toggleClassName: "",
    onMenuItemClicked: () => {},
  };

  constructor(config) {
    const _this = this;
    this.config = Object.assign({}, this.config, config);
    this.$inputSearch = document.getElementById(this.config.inputSearchId);

    if (this?.config?.navigationData?.length && this.isRequiredDomExist()) {
      // Filter the search list if user start to type on search input
      this.$inputSearch.addEventListener(
        "keyup",
        Util.debounce(function () {
          // Perform search only if previously searched text and current text are different
          if (this.previouslySearchedText !== this.value) {
            //Update the previouslySearchedText value
            this.previouslySearchedText = this.value;

            _this.onKeyupSearchInput(this.value);
          }
        }, 200)
      );

      // Open the search list when user focus on search input
      this.$inputSearch.addEventListener("focus", function () {
        _this.onFocusSearchInput();
      });

      // Hide the search list if user click on outside of search element
      this.listenOutsideClicked();
    }
  }

  /**
   * Check the required element exist in DOM
   */
  isRequiredDomExist() {
    // Storing the dom element in local store
    this.$inputSearch = document.getElementById(this.config.inputSearchId);
    this.$searchWrapper = document.getElementById(this.config.wrapperId);
    this.$searchResultList = document.getElementById(this.config.listId);

    if (this.$inputSearch && this.$searchWrapper && this.$searchResultList) {
      return true;
    }
    return false;
  }

  /**
   * Filter the menu list on type text on search input
   */
  onKeyupSearchInput(searchedText) {
    this.openSearch();
    this.searchedText = searchedText;

    // Filter Navigation data by search text
    const filteredNavData = this.filterListBySearchText(
      this.flatNavigationData,
      searchedText
    );

    // If search doesn't matched with list then search not found message
    if (!filteredNavData?.length) {
      this.addClassResultNotFound();
      this.appendNoResultTextInDom(this.$searchResultList);
      return;
    }

    // Generate the list template
    const listElement = this.getListTemplate(filteredNavData);

    // Append search list in dom
    this.appendListElementInDom(this.$searchResultList, listElement);

    // Remove the emptyResult class from search wrapper
    this.removeClassResultNotFound();
  }

  /**
   * Handle call back action after focus on the search input
   */
  onFocusSearchInput() {
    this.openSearch();
    const listElement = this.getListTemplate(this.flatNavigationData);
    this.appendListElementInDom(this.$searchResultList, listElement);

    this.scrollToActiveItem();
  }

  /**
   * Listen the click event clicked out side of search section and close the search list
   */
  listenOutsideClicked() {
    document.addEventListener("click", (event) => {
      if (!this.$searchWrapper.contains(event.target)) {
        this.closeSearch();
      }
    });
  }

  /**
   * Provide the list of search matched DOM elements template
   */
  getListTemplate(listArray) {
    if (!listArray?.length) {
      return "";
    }
    const fragment = document.createDocumentFragment();

    return listArray.reduce((parentElement, list, index) => {
      const li = document.createElement("li");
      const a = document.createElement("a");

      const i = document.createElement("i");
      i.className ="bi bi-arrow-return-right enter-icon";

      const span = document.createElement("span");
      span.innerText = list.label;

      li.dataset.slug = list.slug;

      // Active the element of the list
      if (!index) {
        li.className = this.classNames.activeList;
        this.$activeList = li;
      }

      a.href = "#" + list.slug;
      a.innerHTML = `${this.getIconHtmlBySlug(list.slug)}`;

        // Append enter icon in a tag
      a.appendChild(span);
      a.appendChild(i);

      a.addEventListener("click", (event) => {
        debugger;
        this.handleListClick(event, list.slug, li);
      });

      li.appendChild(a);
      parentElement.appendChild(li);
      return parentElement;
    }, fragment);
  }

  /**
   * Blur the search input
   */
  blurSearchInput() {
    this.$inputSearch.blur();
  }

  /**
   * Search input value
   */
  clearSearchValue() {
    this.$inputSearch.value = "";
  }

  /**
   * Provide the flat navigation data
   */
  get flatNavigationData() {
    if (this.cachedFlatNavigationData) {
      return this.cachedFlatNavigationData;
    }

    if (!this?.config?.navigationData?.length) {
      return [];
    }

    function flattenArray(arr) {
      return arr.reduce((flat, next) => {
        return flat.concat(
          next?.children?.length ? flattenArray(next.children) : next
        );
      }, []);
    }
    this.cachedFlatNavigationData = flattenArray(this.config.navigationData);
    return this.cachedFlatNavigationData;
  }

  /**
   * Filter list by search text
   */
  filterListBySearchText(lists, searchedText) {
    searchedText = searchedText.toLowerCase();
    if (!lists?.length) {
      return [];
    }
    if (!searchedText) {
      return lists;
    }

    return lists.filter((list) =>
      list.label.toLowerCase().includes(searchedText)
    );
  }


  /**
   * Provide the icon's full html
   *
   * @param iconClassName {string} - bootstrap icon class name
   * @returns void
   */
  getIconHtmlBySlug(slug) {
    const type= slug.split('-')[0];
    let iconClassName = '';

    if(type){
      switch(type){
        case 'component':
          iconClassName =  'bi-grid-fill';
          break;
        case 'block':
          iconClassName = 'bi-layout-three-columns';
          break;

        case 'plugin':
          iconClassName = 'bi-plugin';
          break;
      }
    }

    return `<i class="${iconClassName} me-2"></i>`;
  }

  /**
   * Append list element in provided parent element with ul tag
   */
  appendListElementInDom(parentElement, listElement) {
    parentElement.innerHTML = "";

    // Check the provided list element is dom node and only append in parent element
    if (listElement instanceof Node) {
      const ul = document.createElement("ul");
      ul.appendChild(listElement);
      parentElement.appendChild(ul);
    }
  }

  /**
   * Append search not found template in search list
   */
  appendNoResultTextInDom(parentElement) {
    parentElement.innerHTML = "";

    const div = document.createElement("div");
    div.className = "no-search-results";
    div.innerText = `No result for " ${this.searchedText}"`;
    parentElement.appendChild(div);
  }

  /**
   * Handle the list item click event
   */
  handleListClick(event, slug, list) {
    event.preventDefault();

    this.selectList(this.$activeList, list);
    this.$activeList = list;

    if (this?.config?.onMenuItemClicked) {
      this.config.onMenuItemClicked(slug);
    }
  }

  /**
   * add empty result class in search wrapper
   */
  addClassResultNotFound() {
    this.$searchWrapper.classList.add(this.classNames.emptyResult);
  }

  /**
   * remove empty result class in search wrapper
   */
  removeClassResultNotFound() {
    this.$searchWrapper.classList.remove(this.classNames.emptyResult);
  }

  /**
   * Focus the search input
   */
  focusSearch() {
    this.$inputSearch.focus();
  }

  /**
   * Open search result list
   */
  openSearch() {
    if (this.isSearchResultOpened) {
      return;
    }
    this.isSearchResultOpened = true;
    this.$searchWrapper.classList.add(this.classNames.searchOpen);
  }

  /**
   * Close search result list
   */
  closeSearch() {
    if (!this.isSearchResultOpened) {
      return;
    }
    this.blurSearchInput();
    this.clearSearchValue();
    this.isSearchResultOpened = false;
    this.$searchWrapper.classList.remove(this.classNames.searchOpen);
  }

  /**
   * Select the next list item
   */
  selectNextList($list = this.$activeList) {
    if (!this.isSearchResultOpened) {
      return;
    }

    let $newList = $list.nextElementSibling;

    if (!$newList) {
      $newList = $list.parentElement.firstElementChild;
    }

    $list.classList.remove(this.classNames.activeList);
    $newList.classList.add(this.classNames.activeList);
    this.$activeList = $newList;

    /**
     * If the active element is not in visible area then
     * scroll and make visible
     */
    if (this.hasScroll() && !this.isElementVisibleInContainer()) {
      this.scrollToActiveItem();
    }
    return $newList;
  }

  /**
   * Select the previous list item
   */
  selectPreviousList($list = this.$activeList) {
    if (!this.isSearchResultOpened) {
      return;
    }

    let $newList = $list.previousElementSibling;
    if (!$newList) {
      $newList = $list.parentElement.lastElementChild;
    }
    $list.classList.remove(this.classNames.activeList);
    $newList.classList.add(this.classNames.activeList);
    this.$activeList = $newList;

    /**
     * If the active element is not in visible area then
     * scroll and make visible
     */
    if (this.hasScroll() && !this.isElementVisibleInContainer()) {
      this.scrollToActiveItem();
    }
    return $newList;
  }

  /**
   * Select the provided list
   */
  selectList($currentlySelectedList, $newList) {
    $currentlySelectedList.classList.remove(this.classNames.activeList);
    $newList.classList.add(this.classNames.activeList);
  }

  /**
   * Load the selected list [component or block]
   */
  loadSelectedList($list = this.$activeList) {
    if (!this.isSearchResultOpened) {
      return;
    }

    const slug = $list.dataset.slug;
    if (slug && this?.config?.onMenuItemClicked) {
      this.config.onMenuItemClicked(slug);
    }
  }

  /**
   * Scroll the container element so that the active list visible
   */
  scrollToActiveItem() {
    const containerHeight = this.$searchResultList.clientHeight;
    const listHeight = this.$activeList.clientHeight;
    const listOffsetTop = this.$activeList.offsetTop;

    const scrollToAmount = listHeight + listOffsetTop - containerHeight;
    this.$searchResultList.scrollTop = scrollToAmount;
  }

  hasScroll(){
    if (this.$searchResultList.scrollHeight > this.$searchResultList.clientHeight) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check the whether the select item in visible in list or not
   */
  isElementVisibleInContainer() {
    console.log('calculated');
    const container = this.$searchResultList;
    const element = this.$activeList;

    const containerTop = container.scrollTop; //[list top offset]
    const containerBottom = containerTop + container.clientHeight; // [container offset + container height]
    const elementTop = element.offsetTop;
    const elementBottom =
      elementTop + element.clientHeight + element.clientHeight;
    return elementTop >= containerTop && elementBottom <= containerBottom;
  }
}

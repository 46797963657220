class Loader {

    $body;
    $loader;
    isRequiredDOMAvailable = false;

    config = {
        developerModeOn: true,
        selectorId: 'loader',
        loaderClassName: 'loading'
    }

    constructor(config){
        this.config = Object.assign( {}, this.config, config );

        this.$loader = document.getElementById(this.config.selectorId);
        this.$body = document.getElementsByTagName('body')[0];

        if( this.$loader && this.$body){
            this.isRequiredDOMAvailable = true;
            return;
        }

        if(this.developerModeOn){
            log.warn('[Loader][constructor] - Loader is not working(Required DOM is not available', {
                loader: this.$loader,
                body: this.$body
            })
        }
    }

    /**
     * Show the loader
     *
     * @returns void
     */
    show(){
        if(this.isRequiredDOMAvailable){
            this.$body.classList.add(this.config.loaderClassName);
        }
    }

    /**
     * Hide the loader from the DOM
     *
     * @returns void
     */
    hide(){
        if(this.isRequiredDOMAvailable){
            this.$body.classList.remove(this.config.loaderClassName);
        }
    }
}
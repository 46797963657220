class Log {

    /**
     * Log info message
     *
     * @returns void
     */
    info( message, payload ){
        console.info( message, payload );
    }

    /**
     * Log warning message
     *
     * @returns void
     */
    warn( message, payload ){
        console.warn( message, payload );
    }

    /**
     * Log error message
     *
     * @returns void
     */
    error( message, payload){
        console.error( message, payload );
    }
};
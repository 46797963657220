
class FileHandler {

    cache = {};
    $selector;
    config = {
        selectorId: 'right-panel',
        includeFileAttribute: 'ft-include-html',
        pageNotFoundFileLocation: '404.html',
        developerModeOn: true,
        onBeforeLoadFile: ()=> {},
        onAfterLoadFile: ()=>{},
        onAfterLoadedIncludedFile:()=>{}
    };

    constructor( config ){
        this.config = Object.assign( {}, this.config, config);
        this.$selector = document.getElementById(this.config.selectorId);
    }


    removePreviousContent(){
        const scripts = document.querySelector('.script');
        if(scripts){
            scripts.remove();
        }
    }

    /**
     * Load file  and append in selector location
     *
     * @param fileLocation - file location
     */
    async loadFile( fileLocation ){
        if(!this.$selector){
            if(this.config.developerModeOn){
                log.error('[FileHandler][loadFile] - Did found the selector to load file', {
                    selectorId: this.config.selectorId
                })
            }
            return;
        }

        this.removePreviousContent();

        // Emit onBeforeLoadFile hook
        this.config.onBeforeLoadFile( fileLocation );

        const fileContent = await this.getContent(fileLocation)
        this.$selector.innerHTML = fileContent;

        // Remove script tag
        const contentWithScriptTag = this.$selector.querySelector('script');
        
        //Prevent to remove the script file
        // if(contentWithScriptTag){
        //     contentWithScriptTag.remove();
        // }

        // Create a new DOMParser object
        const parser = new DOMParser();

        // Parse the HTML response
        const parsedHtml = parser.parseFromString(fileContent, "text/html");

        // Find the script element that you want to run
        const script = parsedHtml.querySelector("script");

        if(script){
            script.remove();

            // Create a new script element in the current HTML document
            const newScript = document.createElement("script");
            newScript.classList.add('script')

            // Set the innerHTML of the new script element to the script data from the response
            newScript.innerHTML = script.innerHTML;

            // Append the new script element to the body of the current HTML document
            document.body.appendChild(newScript);
        }

        this.includeFileByAttribute();

        // Emit OnAfterLoadFile hook
        this.config.onAfterLoadFile( fileLocation );
    };

    /**
     * Load page not found in $selector
     *
     * @returns void
     */
    loadPageNotFound(){
        this.loadFile(this.config.pageNotFoundFileLocation);
    }

    /**
     * Provide the file content from the cache if it's doesn't exit
     * provide file text from the file location
     *
     * @param fileLocation - file location
     * @returns void
     */
    async getContent(fileLocation) {
        if(this.cache[fileLocation]){
            return this.cache[fileLocation];
        }
        try{
            return await this.getFileText( fileLocation );
        }catch( error ){
            return this.getNotFoundPageFileText();
        }
    }

    /**
     * Provide the page not found page text
     *
     * @returns void
     */
    async getNotFoundPageFileText(){
        if(this.cache[this.config.pageNotFoundFileLocation]){
            return this.cache[this.config.pageNotFoundFileLocation];
        };
        try{
            return this.getFileText(this.config.pageNotFoundFileLocation);
        }catch( error ){
            return error;
        };
    }

    /**
     * Provide the file text
     *
     * @param fileLocation - file location
     * @returns void
     */
    async getFileText( fileLocation ){
        let response = await fetch(fileLocation);
        if (!response.ok) {
            throw new Error( this.errorMessage + fileLocation);
        }
        const fileText = await response.text();
        this.cache[fileLocation] = fileText;
        return fileText;
    }

    /**
     * Clear the cache of provided file location data
     *
     * @param fileLocation - file location
     * @returns void
     */
    clearCache(fileLocation) {
        if(fileLocation){
            delete this.cache[fileLocation];
        }else{
            this.cache = {};
        }
    }

    /**
     * Include the the file from the attribute
     *
     * @returns void
     */
    async includeFileByAttribute(){
        if(!this.config.includeFileAttribute){ return; };

        let allAttributes = document.querySelectorAll(`[${this.config.includeFileAttribute}]`);

        if(!allAttributes.length){ return;}

        const fileRequests = [];

        for (let elements of allAttributes) {
            let fileLocation = elements.getAttribute(this.config.includeFileAttribute);
            if(fileLocation){
                const fileContent = await this.getContent(fileLocation);
                fileRequests.push({element: elements, content: fileContent});
            }
        }

        if(fileRequests.length){
            return Promise.all(fileRequests).then(responses => {
                for (let { element, content } of responses) {
                    element.innerHTML = content;
                    element.removeAttribute(this.config.includeFileAttribute);
                }
                this.config.onAfterLoadedIncludedFile();
            }).catch(error => {
                log.error( '[FileHandler][includeFileByAttribute] - Requested page(s) not found.', {
                    files: fileRequests
                });
            });
        }
    }
}
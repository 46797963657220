var HashHelper = {
    getHashParam: function(){
        const hash = window.location.hash.replace( '#', '');
        return new URLSearchParams(hash);
    },

    get: function (key) {
        var params = this.getHashParam();
        return params.get(key);
    },

    set: function (key, value) {
        var params = this.getHashParam();
        params.set(key, value);
        window.location.hash = params.toString();
    },

    add: function (key, value) {
        var params = this.getHashParam();
        params.append(key, value);
        window.location.hash = params.toString();
    },

    delete: function (key) {
        var params = this.getHashParam();
        params.delete(key);
        window.location.hash = params.toString();
    },

    update: function (key, newValue) {
        var params = this.getHashParam();
        params.set(key, newValue);
        window.location.hash = params.toString();
    },
    has: function (key) {
        var params = this.getHashParam();
        return params.has(key);
    }
}

var navigationData = [
  {
    label: "Components",
    slug: "",
    fileLocation: "",
    iconClassName: "bi bi-grid-fill",
    children: [
      {
        label: "Staff",
        slug: "component-staff",
        fileLocation: "components/staff/sample01/staff.html",
        children: [],
      },
      {
        label: "Section Title",
        slug: "component-section-title",
        fileLocation: "components/section-title/section-title.html",
        children: [],
      },
      {
        label: "Portfolio",
        slug: "component-portfolio",
        fileLocation: "components/portfolio/sample01/portfolio.html",
        children: [],
      },
      {
        label: "Article",
        slug: "component-article",
        fileLocation: "components/article/article.html",
        children: [],
      },
      {
        label: "Button",
        slug: "component-button",
        fileLocation: "components/button/button.html",
        children: [],
      },
      {
        label: "Service",
        slug: "component-service",
        fileLocation: "components/service/service.html",
        children: [],
      },
      {
        label: "Testimonial",
        slug: "component-testimonial",
        fileLocation: "components/testimonial/testimonial.html",
        children: [],
      },
      {
        label: "Call to action",
        slug: "",
        fileLocation: "",
        children: [
          {
            label: "Call to action Sample 01",
            slug: "component-call-to-action-sample-01",
            fileLocation:
              "components/call-to-action/call-to-action-sample-01/call-to-action-sample-01.html",
            children: [],
          },
          {
            label: "Call to action Sample 02",
            slug: "component-call-to-action-sample-02",
            fileLocation:
              "components/call-to-action/call-to-action-sample-02/call-to-action-sample-02.html",
            children: [],
          },
        ],
      },
      {
        label: "Header",
        slug: "component-header",
        fileLocation: "components/header/header.html",
        children: [],
      },
      {
        label: "Navigation",
        slug: "component-navigation",
        fileLocation: "components/navigation/navigation.html",
        children: [],
      },
      {
        label: "Slider",
        slug: "component-slider",
        fileLocation: "components/slider/slider.html",
        children: [],
      },
      {
        label: "Counter",
        slug: "component-counter",
        fileLocation: "components/counter/counter.html",
        children: [],
      },
    ],
  },
  {
    label: "Blocks",
    slug: "",
    fileLocation: "",
    iconClassName: "bi bi-layout-three-columns",
    children: [
      {
        label: "Article",
        slug: "block-article",
        fileLocation: "blocks/article/article.html",
        children: [],
      },
      {
        label: "Counter",
        slug: "block-counter",
        fileLocation: "blocks/counter/counter.html",
        children: [],
      },
      {
        label: "Staff",
        slug: "block-staff",
        fileLocation: "blocks/staff/staff.html",
        children: [],
      },
      {
        label: "Portfolio",
        slug: "block-portfolio",
        fileLocation: "blocks/portfolio/portfolio.html",
        children: [],
      },
      {
        label: "Services",
        slug: "block-services",
        fileLocation: "blocks/services/services.html",
        children: [],
      },
      {
        label: "Testimonials",
        slug: "block-testimonials",
        fileLocation: "blocks/testimonials/testimonials.html",
        children: [],
      },
      {
        label: "Header",
        slug: "block-header",
        fileLocation: "blocks/header/header.html",
        children: [],
      },
    ],
  },
  {
    label: "Plugins",
    slug: "",
    fileLocation: "",
    iconClassName: "bi bi-plugin",
    children: [
      {
        label: "Search",
        slug: "plugin-search",
        fileLocation: "plugins/search/search.html",
        children: [],
      },
      {
        label: "Cookie policy",
        slug: "cookie-policy",
        fileLocation: "plugins/cookie-policy/cookie-policy.html",
        children: [],
      },
    ],
  },
  {
    label: "Pages",
    slug: "",
    fileLocation: "",
    iconClassName: "bi bi-file-earmark-fill",
    children: [
      {
        label: "Home default",
        slug: "page-home-default",
        fileLocation: "pages/home/default.html",
        children: [],
      },
    ],
  },
];

// Sort the navigation with
navigationData = navigationData.map((nav) => {
  const sortedChildren = nav.children.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });
  return { ...nav, children: sortedChildren };
});

const HAS_KEY = {
  SLUG: "slug",
  SIDEBAR: "sidebar",
};

const KEYS = {
  CONTROL: "Control",
  K: "k",
  B: "b",
  ESCAPE: "Escape",
  ARROW_UP: "ArrowUp",
  ARROW_DOWN: "ArrowDown",
  ENTER: "Enter",
};

class Frame {
  // Developer mode
  DEVELOPER_MODE_ON = true;

  // Class instances
  sidebarNavigationInstance;
  fileHandlerInstance;
  loggerInstance;
  loaderInstance;
  sidebarTogglerInstance;
  searchInstance;
  getCodeInstance;

  constructor() {
    this.logInstance = new Log();

    // Make it available whole application without passing in config
    window.log = this.logInstance;

    document.addEventListener("DOMContentLoaded", () => {
      this.initApp();
    });
  }

  /**
   * Initializing the application
   *
   * @returns void
   */
  initApp() {
    this.initLoader();
    this.initSidebarToggler();
    this.initFileHandler();
    this.initSidebarNavigation();
    this.initSearch();
    this.listenKeyPressed();
    this.initGetCode();
  }

  /**
   * Initialize the loader show loader while fetching files
   * @returns void
   */
  initLoader() {
    const config = {
      developerModeOn: this.DEVELOPER_MODE_ON,
    };
    this.loaderInstance = new Loader(config);
  }

  /**
   * Initialize the search which enable search feature for block and component
   */
  initSearch() {
    const config = {
      wrapperId: "search-wrapper",
      inputSearchId: "search-input",
      listId: "search-result-list",
      navigationData: navigationData,
      onMenuItemClicked: (slug) => {
        const hasSlugValue = HashHelper.get(HAS_KEY.SLUG);

        // Prevent to load file which is already loaded
        if (!slug || hasSlugValue === slug) {
          return;
        }

        // Update the URL hash value
        HashHelper.set(HAS_KEY.SLUG, slug);

        const { fileLocation } =
          this.sidebarNavigationInstance.getItemDataBySlug(slug);
        if (fileLocation) {
          this.fileHandlerInstance.loadFile(fileLocation);
          this.sidebarNavigationInstance.activeMenuItemBySlug(slug);
        }
      },
    };

    this.searchInstance = new Search(config);
  }

  /**
   * Initialize the sidebar toggler which toggle the sidebar menu
   * @returns void
   */
  initSidebarToggler() {
    const config = {
      developerModeOn: this.DEVELOPER_MODE_ON,
      hashKey: HAS_KEY.SIDEBAR,
      showSidebar: !HashHelper.get(HAS_KEY.SLUG),
    };
    this.sidebarTogglerInstance = new SidebarToggler(config);
  }

  /**
   * Initialize the file handler which load the files in dynamic content id
   * @returns void
   */
  initFileHandler() {
    const config = {
      developerModeOn: this.DEVELOPER_MODE_ON,
      onBeforeLoadFile: () => {
        this.loaderInstance.show();
      },
      onAfterLoadFile: () => {
        this.loaderInstance.hide();
        this.getCodeInstance.appendHtmlToModal();
      },
      onAfterLoadedIncludedFile: () => {
        this.getCodeInstance.appendHtmlToModal();
      },
    };

    //Initialize the file handler
    this.fileHandlerInstance = new FileHandler(config);
  }

  /**
   * Append navigation in sidebar
   *
   * @returns void
   */
  initSidebarNavigation() {
    const config = {
      developerModeOn: this.DEVELOPER_MODE_ON,
      selector: "navigation",
      hashKey: HAS_KEY.SLUG,
      navigationData: navigationData,
      onItemClicked: (slug, fileLocation, event) => {
        this.fileHandlerInstance.loadFile(fileLocation);
      },
      onAfterAppend: () => {
        // Restore the previous state of app
        this.restorePreviousStates();
        this.listenHashValueChange();
      },
    };
    this.sidebarNavigationInstance = new SidebarNavigation(config);
    this.sidebarNavigationInstance.append();

    this.sidebarNavigationInstance.activeNavItemOnChange();
  }

  /**
   * Initialize the  Get code feature
   */
  initGetCode() {
    const config = {};
    this.getCodeInstance = new GetCode();
  }
  /**
   * Listen window hashchange and restore the state accordingly
   */
  listenHashValueChange() {
    addEventListener("hashchange", (even) => {
      this.restorePreviousStates();
    });
  }

  /**
   * Restore the previous state of application
   *
   * @returns void
   */
  restorePreviousStates() {
    const hashParam = Object.fromEntries(HashHelper.getHashParam());
    if (hashParam) {
      for (const key in hashParam) {
        this.setInitialStates({ name: key, value: hashParam[key] });
      }
    }
  }

  /**
   * Restore the state
   * @param state - Previous state restore from url
   *
   * @returns void
   */
  setInitialStates(state) {
    switch (state.name) {
      case HAS_KEY.SIDEBAR:
        if (state.value === "true") {
          this.sidebarTogglerInstance.open();
        } else {
          this.sidebarTogglerInstance.close();
        }
        break;

      case HAS_KEY.SLUG:
        const fileLocation =
          this.sidebarNavigationInstance.getFileLocationBySlug(state.value);

        // If slug is not exist then show page not found page
        if (!fileLocation) {
          this.fileHandlerInstance.loadPageNotFound();
          return;
        }

        this.fileHandlerInstance.loadFile(fileLocation);
        this.sidebarNavigationInstance.activeMenuItemBySlug(state.value);
        break;
    }
  }

  /**
   * Listen the key press event and focus on search
   */
  listenKeyPressed() {
    document.addEventListener("keydown", (event) => {
      const key = event.key;
      const ctrl = event.ctrlKey;
      const meta = event.metaKey;

      switch (key) {
        case KEYS.ESCAPE:
          this.searchInstance.closeSearch();
          break;

        case KEYS.ARROW_UP:
          this.searchInstance.selectPreviousList(this.$activeList);
          // Your code to trigger an event for the up arrow key here
          break;

        case KEYS.ARROW_DOWN:
          this.searchInstance.selectNextList(this.$activeList);
          // Your code to trigger an event for the down arrow key here
          break;

        case KEYS.ENTER:
          this.searchInstance.loadSelectedList(this.$activeList);
          this.searchInstance.closeSearch();
          // Your code to trigger an event for the Enter key here
          break;

        case KEYS.K:
          if (ctrl || meta) {
            this.searchInstance.focusSearch();
            this.searchInstance.onFocusSearchInput();
          }
          break;

        case KEYS.B:
          if (ctrl || meta) {
            this.sidebarTogglerInstance.toggle();
          }
          break;
      }
    });
  }
}

new Frame();

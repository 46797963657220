class GetCode {
  config = {
    copyToClipboardButtonId: "ftds-copy-html",
    successClipboardIcon: '<i class="bi bi-clipboard-check"></i>',
    clipboardIcon: '<i class="bi bi-clipboard"></i>',
    copiedTooltipText: "Copied",
    tooltipText: "Copy to clipboard",
  };

  constructor(config) {
    this.config = Object.assign({}, this.config, config);
    this.copyHtmlCodeInClipboard();
  }

  /**
   * Copy html code in clipboard
   */
  copyHtmlCodeInClipboard() {
    const $clipboardElement = document.getElementById(
      this.config.copyToClipboardButtonId
    );

    if (!$clipboardElement) {
      return;
    }

    const clipboard = new ClipboardJS($clipboardElement);
    clipboard.on("success", (e) => {
      var $element = e.trigger;
      $element.innerHTML = this.config.successClipboardIcon;
      /* htmlCopyToClipboardTooltip this variable globally declared in index.html */
      htmlCopyToClipboardTooltip.setContent({
        ".tooltip-inner": this.config.copiedTooltipText,
      });

      /* Update Clipboard icon and tooltip text */
      setTimeout(() => {
        /* htmlCopyToClipboardTooltip this variable globally declared in index.html */
        htmlCopyToClipboardTooltip.setContent({
          ".tooltip-inner": this.config.tooltipText,
        });
        $element.innerHTML = this.config.clipboardIcon;
      }, 3000);
      e.clearSelection();
    });
  }

  /**
   * Add the right panel code to modal code section
   */
  appendHtmlToModal() {
    const htmlCodeSelectionSelector = document.getElementById("html");
    const rightSelectionPanel = document.getElementById("right-panel");

    if (htmlCodeSelectionSelector && rightSelectionPanel) {
      const codeSnippet = rightSelectionPanel.innerHTML;
      const encodedCodeSnippet = codeSnippet
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      htmlCodeSelectionSelector.innerHTML = encodedCodeSnippet;
    }
  }

  openModal() {}

  closeModal() {}
}
